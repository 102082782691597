@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display');

#root {
  text-align: center;
  height: 100%;
  width: 100%;
  /* overflow-x: hidden; */
  font-family: 'Major Mono Display', monospace;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
